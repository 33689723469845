import './fullscreen-section.scss';

class FullscreenSection {
    constructor (element, options) {
        const defaults = {
            initAttr: 'data-fullscreensection',
            mediaAttr: 'media',
            eventScroller: null,
            offset: 0
        };

        this.settings = Object.assign({}, defaults, options);

        this.$fullscreenSection = element;
        this.$fullscreenSectionMedia = this.$fullscreenSection.querySelector('[' + this.settings.initAttr + '="' + this.settings.mediaAttr + '"]');
        this.position = 0;
    }

    initialize () {
        this.setStickyImage(this.$fullscreenSection);
        this.setEvents();
        this.setBackgroundColor();
    }

    setStickyImage ($element) {
        const offset = this.settings.offset;
        const elementDimensions = $element.getBoundingClientRect();
        const elementTop = parseInt(elementDimensions.top);

        if (elementTop - offset > 0) {
            if (this.$fullscreenSection.classList.contains('is--sticky')) {
                this.$fullscreenSection.classList.remove('is--sticky');
            }
        } else {
            this.$fullscreenSection.classList.add('is--sticky');
        }
    }

    setEvents () {
        const eventScroller = this.settings.eventScroller;

        if (eventScroller !== null) {
            eventScroller.customFunctions.push(() => {
                this.setStickyImage(this.$fullscreenSection);
            });
        }
    }

    setBackgroundColor () {
        if (this.$fullscreenSection) {
            const $elementBefore = this.$fullscreenSection.previousElementSibling;
            if ($elementBefore) {
                let fullscreenBgColorClass = '';
                if ($elementBefore.classList.contains('is--development') || $elementBefore.classList.contains('is--white')) {
                    fullscreenBgColorClass = 'is--white';
                } else if ($elementBefore.classList.contains('is--competitive') || $elementBefore.classList.contains('is--black')) {
                    fullscreenBgColorClass = 'is--black';
                } else if ($elementBefore.classList.contains('is--cyclist') || $elementBefore.classList.contains('is--red')) {
                    fullscreenBgColorClass = 'is--red';
                } else {
                    const elementColor = window.getComputedStyle($elementBefore, '::before').getPropertyValue('background-color');
                    const hexColor = elementColor
                        .match(/(\d+), (\d+), (\d+)/)
                        .slice(1, 4)
                        .map((number) => { return Number(number).toString(16).padStart(2, '0'); })
                        .join('')
                        .replace(/^/, '#');
                    if (hexColor === '#000000') {
                        fullscreenBgColorClass = 'is--black';
                    } else if (hexColor === '#ffffff' || hexColor === '#FFFFFF') {
                        fullscreenBgColorClass = 'is--white';
                    } else if (hexColor === '#E3000B' || hexColor === '#e3000b') {
                        fullscreenBgColorClass = 'is--red';
                    }
                }

                if (fullscreenBgColorClass !== '') {
                    this.$fullscreenSection.classList.add(fullscreenBgColorClass);
                }
            }

            const $elementAfter = this.$fullscreenSection.nextElementSibling;

            if ($elementAfter) {
                let fullscreenInnerBgColorClass = '';
                if ($elementAfter.classList.contains('is--development') || $elementAfter.classList.contains('is--white')) {
                    fullscreenInnerBgColorClass = 'has--fullscreen-inner-white';
                } else if ($elementAfter.classList.contains('is--competitive') || $elementAfter.classList.contains('is--black')) {
                    fullscreenInnerBgColorClass = 'has--fullscreen-inner-black';
                } else if ($elementAfter.classList.contains('is--cyclist') || $elementAfter.classList.contains('is--red')) {
                    fullscreenInnerBgColorClass = 'has--fullscreen-inner-red';
                } else {
                    const elementColor = window.getComputedStyle($elementAfter, '::before').getPropertyValue('background-color');
                    const hexColor = elementColor
                        .match(/(\d+), (\d+), (\d+)/)
                        .slice(1, 4)
                        .map((number) => { return Number(number).toString(16).padStart(2, '0'); })
                        .join('')
                        .replace(/^/, '#');
                    if (hexColor === '#000000') {
                        fullscreenInnerBgColorClass = 'has--fullscreen-inner-black';
                    } else if (hexColor === '#ffffff' || hexColor === '#FFFFFF') {
                        fullscreenInnerBgColorClass = 'has--fullscreen-inner-white';
                    } else if (hexColor === '#E3000B' || hexColor === '#e3000b') {
                        fullscreenInnerBgColorClass = 'has--fullscreen-inner-red';
                    }
                }

                if (fullscreenInnerBgColorClass !== '') {
                    this.$fullscreenSection.classList.add(fullscreenInnerBgColorClass);
                }
            }
        }
    }
}

export { FullscreenSection };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $fullscreenSections = $context.querySelectorAll('[data-fullscreensection="root"]');

        if ($fullscreenSections.length > 0) {
            for (let i = 0; i < $fullscreenSections.length; i++) {
                const $fullscreenSection = new FullscreenSection($fullscreenSections[i], {
                    eventScroller: window.eventScroller
                });
                $fullscreenSection.initialize();
            }
        }
    }
});
